import { Box, CircularProgress } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { SlideIn } from "../SlideIn";

export const Loading: FC<PropsWithChildren> = ({ children }) => (
  <SlideIn key="loading">
    <Box
      textAlign="center"
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress size="5rem" />
      {children}
    </Box>
  </SlideIn>
);
