import { Box } from "@mui/material";
import React, { FC, useEffect, useState, PropsWithChildren } from "react";

import "./SlideIn.css";

export const SlideIn: FC<PropsWithChildren> = ({ children }) => {
  const [showing, setShowing] = useState<boolean>(false);

  useEffect(() => {
    if (!showing) {
      setShowing(() => true);
    }
  }, [showing]);

  return (
    <Box className={showing ? "slidein slidein-showing" : "slidein"}>
      {children}
    </Box>
  );
};
