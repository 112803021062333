import { SentimentDissatisfied } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { FC, PropsWithChildren } from "react";

export const Error: FC<PropsWithChildren> = ({ children }) => {
  const { palette } = useTheme();
  return (
    <Box
      textAlign="center"
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <SentimentDissatisfied color="error" sx={{ fontSize: 120 }} />
      <Box sx={{ color: palette.error.main }}>{children}</Box>
    </Box>
  );
};
