import {
  Box,
  createTheme,
  CssBaseline,
  Link,
  ThemeProvider,
  Typography,
} from "@mui/material";
import AppBody from "./Pages/LandingPage";

import "./Fonts/neuropolitical.css";
import VetChipLogo from "./VetChipLogo";

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ p: 1, textAlign: "center" }}>
        <Link
          href="https://www.vetchip.com.au"
          fontSize="small"
          underline="none"
          target="_blank"
          sx={{ display: "block", padding: 0 }}
        >
          <VetChipLogo size="1rem" />
        </Link>
      </Box>
      <Typography
        align="center"
        variant="h5"
        sx={{ mt: 2, fontFamily: "neuropolitical", fontWeight: "100" }}
      >
        Lost Pet Service
      </Typography>
      <AppBody />
      <Box
        justifyContent="space-between"
        alignItems="center"
        sx={{
          display: "flex",
          p: 1,
          position: "fixed",
          bottom: 0,
          bgcolor: "#CCC",
          width: "100vw",
        }}
      >
        <Typography fontSize="small">
          <Link
            href="https://www.vetchip.com.au/privacy-policy"
            underline="none"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    </ThemeProvider>
  );
}

export default App;
