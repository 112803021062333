import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button as ListItemButton,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton as Button,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Announcement,
  Close,
  Facebook,
  Instagram,
  NavigateNext,
  Person,
  Phone,
  ShareLocationOutlined,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { SlideIn } from "../SlideIn";
import { Error } from "./Error";
import { Loading } from "./Loading";

const healthStatusOptions = ["Healthy", "Injured", "Sick", "Dead"];

const getAnimalIdFromUrl = (str: string | null) => {
  return (
    str
      // split into 2 char chunks (keeping chunks by using brackets)
      ?.split(/(..)/)
      // reverse the array
      .reverse()
      // rejoin...
      .join("")
      // insert a - after every 4th char...
      .replaceAll(/(....(?!$))/g, "$1-")
  );
};

const checkLocationAvailable = () => {
  if ("geolocation" in navigator) {
    return true;
  } else {
    return false;
  }
};

interface ContactDetails {
  name: string;
  phone: string;
  address: string;
}

interface PetDetailsResponse {
  id: string;
  sToken: string;
  name: string;
  isLost: boolean;
  medicalStatus?: string;
  vetDetails?: ContactDetails;
  shelterDetails?: ContactDetails;
}

export default function AppBody() {
  const [policyOpen, setPolicyOpen] = useState(false);
  const [gettingCoords, setGettingCoords] = useState(false);
  const [coords, setCoords] = useState<GeolocationCoordinates>();
  const [petDetails, setPetDetails] = useState<PetDetailsResponse>();
  const [petDetailsError, setPetDetailsError] = useState<any>();
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [healthStatusMenuOpen, setHealthStatusMenuOpen] =
    useState<HTMLElement | null>();
  const [geolocationError, setGeolocationError] = useState<
    string | undefined
  >();
  const [started, setStarted] = useState(false);
  const [formInfo, setFormInfo] = useState<Record<string, string>>({
    status: "Healthy",
  });

  const params = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search]
  );

  const animalId = getAnimalIdFromUrl(params.get("a"));
  const sToken = params.get("s");

  useEffect(() => {
    if (coords != null && petDetails == null && petDetailsError == null) {
      fetch("/api/stage1", {
        method: "POST",
        headers: {
          Authorization: null,
          Accept: "application/json",
          "Content-Type": "application/json",
        } as any,
        body: JSON.stringify({
          animalId,
          sToken,
          lat: coords?.latitude,
          lon: coords?.longitude,
          acc: coords?.accuracy,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          setPetDetails(json);
        })
        .catch((e) => {
          console.error(e);
          setPetDetailsError(e);
        });
    }
  }, [coords, animalId, sToken, petDetails, petDetailsError]);

  const isNameValid = useMemo(
    () => formInfo?.finder != null && formInfo?.finder.trim() !== "",
    [formInfo?.finder]
  );

  const isPhoneValid = useMemo(
    () =>
      formInfo?.phone != null &&
      /^(\+[1-9]{1,2}|0)[0-9]{9}$/.exec(formInfo.phone.trim()) != null,
    [formInfo?.phone]
  );

  // useMemo(() => {
  //   console.debug("FormInfo", formInfo);
  // }, [formInfo]);

  const isFormValid = useMemo(() => {
    return isNameValid && isPhoneValid;
  }, [isNameValid, isPhoneValid]);

  const submitDetails = async () => {
    if (isFormValid) {
      try {
        setSubmitted(true);
        setSubmitting(true);
        const response = await fetch("/api/stage2", {
          method: "POST",
          headers: { Authorization: null } as any,
          body: JSON.stringify({
            animalId: petDetails?.id,
            sToken: petDetails?.sToken,
            lat: coords?.latitude,
            lon: coords?.longitude,
            acc: coords?.accuracy,
            ...formInfo,
          }),
        });
        setSubmitSuccess((await response.json()) === true);
        setSubmitting(false);
      } catch (e) {
        console.error(e);
        setSubmitSuccess(false);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handlePolicyOpen = () => {
    setPolicyOpen(true);
  };

  const handlePolicyClose = () => {
    setPolicyOpen(false);
  };

  if (!animalId) {
    return (
      <SlideIn key="error">
        <Error>
          <p>Error: could not identify implant.</p>
          <p>Try scanning again.</p>
        </Error>
      </SlideIn>
    );
  }

  if (!started) {
    return (
      <Box textAlign="center" sx={{ p: 2 }}>
        <Typography variant="h4" color="inherit" align="center" sx={{ m: 2 }}>
          Welcome
        </Typography>
        <Divider sx={{ m: 2 }} />
        <Typography variant="h6" color="inherit" align="center">
          You've just scanned an animal with a VetChip™ implant.
        </Typography>
        <Typography
          variant="h6"
          color="inherit"
          align="center"
          sx={{ m: 2, pt: 2 }}
        >
          If you think the animal is missing, you can report it by following a
          few simple steps.
        </Typography>
        <br />
        <ListItemButton
          variant="contained"
          size="large"
          endIcon={<NavigateNext sx={{ height: 40, width: 40 }} />}
          sx={{
            fontSize: "large",
            m: 2,
            minWidth: 100,
            maxWidth: 250,
            alignSelf: "center",
          }}
          onClick={() => {
            setStarted(true);
          }}
        >
          Get Started
        </ListItemButton>
      </Box>
    );
  }

  if (!coords) {
    return (
      <SlideIn key="coords">
        <Box textAlign="center" sx={{ p: 2 }}>
          <Typography variant="h4" color="inherit" align="center" sx={{ m: 2 }}>
            Where are you located?
          </Typography>
          <Divider sx={{ m: 2 }} />
          <Typography variant="h6" color="inherit" align="center">
            Please share your location to continue.
          </Typography>
          <Link
            href="#"
            underline="none"
            align="center"
            onClick={handlePolicyOpen}
            sx={{ mt: 2, fontSize: "medium" }}
          >
            Why do we need this?
          </Link>
          <br />
          <Dialog
            open={policyOpen}
            onClose={handlePolicyClose}
            fullWidth
            scroll="paper"
          >
            <DialogTitle align="center">Why we need your location</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box>
                  We need to know the location of the missing animal so we can
                  notify the owner.
                </Box>
                <Box sx={{ mt: 2 }}>
                  Your location data will only be shared with the owner or a
                  registered vet. We will not use your location data for
                  tracking or marketing purposes.
                </Box>
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Link underline="none">Privacy Policy</Link>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ListItemButton
                size="large"
                variant="contained"
                onClick={handlePolicyClose}
              >
                Ok
              </ListItemButton>
            </DialogActions>
          </Dialog>

          <ListItemButton
            size="large"
            variant="contained"
            endIcon={
              gettingCoords ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : (
                <ShareLocationOutlined sx={{ height: 40, width: 40 }} />
              )
            }
            sx={{
              fontSize: "large",
              m: 2,
              minWidth: 100,
              maxWidth: 250,
              alignSelf: "center",
            }}
            onClick={() => {
              if (checkLocationAvailable()) {
                setGettingCoords(true);
                // setTimer(setTimeout(() => {
                //   setGeoLocationTimedOut(true)
                // }, 5000));
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    // clearTimeout(timer);
                    // setTimer(null)
                    setGeolocationError(undefined);
                    setCoords(position.coords);
                  },
                  (error) => {
                    setGeolocationError(error.message);
                    setGettingCoords(false);
                  },
                  {
                    timeout: 20000,
                  }
                );
              }
              //throw error
            }}
          >
            Continue
          </ListItemButton>
          <Collapse in={!!geolocationError} sx={{ mt: 2, textAlign: "left" }}>
            <Alert
              severity="error"
              action={
                <Button
                  aria-label="Ok"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setGeolocationError(undefined);
                  }}
                >
                  <Close fontSize="inherit" />
                </Button>
              }
            >
              <AlertTitle>Error</AlertTitle>
              {geolocationError}
            </Alert>
          </Collapse>
        </Box>
      </SlideIn>
    );
  }

  // have pet details, but missing required data
  if (petDetailsError) {
    return (
      <SlideIn key="error">
        <Error>
          <p>Error: could not indentify implant.</p>
          <p>
            Try scanning again, or perhaps the animal isn't registered in the
            database.
          </p>
        </Error>
      </SlideIn>
    );
  }

  // getting pet details from stage1 api
  if (!petDetails) {
    return (
      <Loading key="details">
        <Typography variant="h5" sx={{ mt: 2 }}>
          Loading Pet Details
        </Typography>
      </Loading>
    );
  }

  // have pet details, but have not uploaded contact details yet...
  if (!submitted) {
    return (
      <SlideIn key="form">
        <Box textAlign="center" sx={{ p: 2 }}>
          <Stack>
            <Typography variant="body1" color="inherit" textAlign="center">
              You have found:{" "}
            </Typography>
            <Typography variant="h5" textAlign="center" sx={{ my: 2 }}>
              {petDetails.name ?? "<No name recorded>"}
            </Typography>
            {petDetails.medicalStatus !== "" && (
              <Typography variant="body1" color="inherit" textAlign="center">
                {petDetails.medicalStatus}
              </Typography>
            )}
            {petDetails.isLost && (
              <Stack direction="row" justifyContent="center" spacing="0.5rem">
                {/* <Report color="error" /> */}
                <Typography variant="body1" color="inherit" textAlign="center">
                  Help reunite {petDetails.name ?? " them "} with their owner.
                </Typography>
              </Stack>
            )}

            <br />
            <Typography
              variant="body1"
              color="inherit"
              textAlign="center"
              sx={{ m: 1 }}
            >
              Please provide your contact details:
            </Typography>

            <Box component="form" autoComplete="off" sx={{ m: 1 }}>
              <Box
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <FormControl variant="standard" sx={{ minWidth: "90%" }}>
                  <TextField
                    id="name-input"
                    label="Name"
                    type="name"
                    autoComplete="name"
                    error={!isNameValid}
                    variant="outlined"
                    onChange={(event) => {
                      setFormInfo((current) => ({
                        ...current,
                        finder: event.target.value,
                      }));
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ m: 1.5, minWidth: "90%", textAlign: "center" }}
                  />
                  <TextField
                    id="phone-input"
                    label="Phone Number"
                    type="tel"
                    autoComplete="mobile tel"
                    variant="outlined"
                    error={!isPhoneValid}
                    value={formInfo.phone ?? ""}
                    onChange={(event) => {
                      setFormInfo((current) => ({
                        ...current,
                        phone:
                          (event.target.value.startsWith("4") ? "0" : "") +
                          event.target.value,
                      }));
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ m: 1.5, minWidth: "90%", textAlign: "center" }}
                  />
                  <Typography
                    variant="body1"
                    color="inherit"
                    textAlign="center"
                    sx={{ m: 1 }}
                  >
                    Please provide additional information for the owner:
                  </Typography>
                  <Paper sx={{ m: 1.5, minWidth: "90%", textAlign: "center" }}>
                    <List component="nav" sx={{ bgcolor: "background.paper" }}>
                      <ListItem
                        button
                        aria-label="Animal Health Status"
                        onClick={(e) =>
                          setHealthStatusMenuOpen(e.currentTarget)
                        }
                      >
                        <ListItemText secondary={formInfo?.status}>
                          Animal Health Status
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Paper>
                  <Menu
                    anchorEl={healthStatusMenuOpen}
                    open={healthStatusMenuOpen != null}
                    onClose={() => setHealthStatusMenuOpen(null)}
                  >
                    {healthStatusOptions.map((option) => (
                      <MenuItem
                        key={option}
                        selected={formInfo?.status === option}
                        onClick={() => {
                          setHealthStatusMenuOpen(null);
                          setFormInfo((current) => ({
                            ...current,
                            status: option,
                          }));
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                  <TextField
                    multiline
                    id="owner-msg"
                    variant="outlined"
                    label="Message for the owner"
                    error={formInfo.message?.length > 99}
                    inputProps={{ maxLength: 100 }}
                    InputProps={{
                      startAdornment: formInfo.message === "" && (
                        <InputAdornment position="start">
                          <Announcement />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      setFormInfo((current) => ({
                        ...current,
                        message: event.target.value,
                      }));
                    }}
                    sx={{ m: 1.5, minWidth: "90%", textAlign: "center" }}
                  />
                  <Typography
                    variant="body2"
                    color={formInfo.message?.length > 80 ? "red" : "inherit"}
                  >
                    Using {formInfo.message?.length ?? 0} of 100 characters.
                  </Typography>
                </FormControl>
              </Box>
            </Box>
            <ListItemButton
              disabled={!isFormValid}
              size="large"
              variant="contained"
              endIcon={<NavigateNext sx={{ height: 40, width: 40 }} />}
              sx={{
                m: 0.5,
                pb: "3",
                fontSize: "large",
                minWidth: 100,
                maxWidth: 250,
                alignSelf: "center",
              }}
              onClick={async () => {
                submitDetails();
              }}
            >
              Submit
            </ListItemButton>
            <p />
          </Stack>
        </Box>
      </SlideIn>
    );
  }

  // have submitted details, but not receieved a response
  if (submitting) {
    return (
      <Loading key="submitting">
        <Typography variant="h5" sx={{ mt: 2 }}>
          Notifying Owner
        </Typography>
      </Loading>
    );
  }

  if (submitSuccess === false) {
    return (
      <SlideIn key="error">
        <Error>
          <p>Failed to send message</p>
          <p>Unknown error!</p>
          <p>Sorry for the inconvenience. Please try again soon.</p>
          <Button
            onClick={async () => {
              await submitDetails();
            }}
          >
            Retry
          </Button>
        </Error>
      </SlideIn>
    );
  }

  return (
    <SlideIn key="thankyou">
      <Box textAlign="center" sx={{ p: 2 }}>
        <Typography variant="h4" color="inherit" align="center" sx={{ m: 2 }}>
          Thank you!
        </Typography>
        <Typography variant="h6" color="inherit" align="center" sx={{ m: 2 }}>
          A notification has been sent to {petDetails.name ?? "the animal"}'s
          owner.
        </Typography>
        <Typography variant="h6" color="inherit" align="center" sx={{ m: 2 }}>
          If you are unable to care for {petDetails.name ?? "the animal"} at the
          moment, please contact your local council to arrange a temporary care
          plan.
        </Typography>
        <br />
        <Typography variant="h6" color="inherit" align="center" sx={{ m: 2 }}>
          More about us:
        </Typography>
        <Link
          fontSize="large"
          sx={{ m: 2 }}
          href="https://www.vetchip.com.au/"
          underline="none"
        >
          Our Website
        </Link>
        <Stack direction="row" justifyContent="center">
          <Button
            size="large"
            color="inherit"
            href="https://www.facebook.com/VetChipOfficial/"
          >
            <Facebook fontSize="large" />
          </Button>
          <Button
            size="large"
            color="inherit"
            href="https://www.instagram.com/accounts/login/?next=/vetchipofficial/"
          >
            <Instagram fontSize="large" />
          </Button>
          <Button
            size="large"
            color="inherit"
            href="https://www.youtube.com/channel/UCVZtWNWnKVauyTep2PGB5sg"
          >
            <YouTube fontSize="large" />
          </Button>
          <Button
            size="large"
            color="inherit"
            href="https://twitter.com/VetChipOfficial"
          >
            <Twitter fontSize="large" />
          </Button>
        </Stack>
      </Box>
    </SlideIn>
  );
}
