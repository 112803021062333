import { Box, Stack, Typography } from "@mui/material";
import VCLogo from "./Icons/vetchip-logo-small.svg";

export default function VetChipLogo({ size = "56px" }) {
  return (
    <Stack direction="row" justifyContent="left" alignItems="stretch">
      <Typography
        style={{ color: "#1070b6" }}
        sx={{
          fontFamily: "neuropolitical",
          fontWeight: "100",
          fontSize: size || "1rem",
          lineHeight: "1",
        }}
      >
        Vet
      </Typography>
      <Typography
        style={{ color: "#4d4e4e" }}
        sx={{
          fontFamily: "neuropolitical",
          fontWeight: "100",
          fontSize: size || "1rem",
          lineHeight: "1",
        }}
      >
        Chip
      </Typography>
      <Box
        component="img"
        src={VCLogo}
        sx={{
          ml: `calc(${size}/8)`,
          width: size || "1rem",
          height: size || "1rem",
          verticalAlign: "middle",
        }}
      />
    </Stack>
  );
}
